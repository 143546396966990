//Carrusel full photo
const swiperPhotocall2022 = new Swiper(".swiper-photocall-2022", {
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
})

const swiperSortida2022 = new Swiper(".swiper-sortida-2022", {
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
})

const swiperPuntspas2022 = new Swiper(".swiper-puntspas-2022", {
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
})

const swiperRuta2022 = new Swiper(".swiper-ruta-2022", {
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
})

const swiperPreparacio2023 = new Swiper(".swiper-preparacio-2023", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
})

const swiperPhotocall2023 = new Swiper(".swiper-photocall-2023", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
})

const swiperSortida2023 = new Swiper(".swiper-sortida-2023", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
})

const swiperArribada2023 = new Swiper(".swiper-arribada-2023", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
})

const swiperPuntspas2023 = new Swiper(".swiper-puntspas-2023", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
})

const swiperVendrell2023 = new Swiper(".swiper-vendrell-2023", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
})

const swiperVilagrasseta2023 = new Swiper(".swiper-vilagrasseta-2023", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
})

const swiperJocs2023 = new Swiper(".swiper-jocs-2023", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
})

const swiperPhotocall2024 = new Swiper(".swiper-photocall-2024", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
})

const swiperSortida2024 = new Swiper(".swiper-sortida-2024", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
})

const swiperSindicat2024 = new Swiper(".swiper-sindicat-2024", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
})

const swiperClop2024 = new Swiper(".swiper-clop-2024", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
})

const swiperLloverola2024 = new Swiper(".swiper-lloverola-2024", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
})

const swiperEstelvio2024 = new Swiper(".swiper-estelvio-2024", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
})

const swiperSerrateix2024 = new Swiper(".swiper-serrateix-2024", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
})

const swiperArribada2024 = new Swiper(".swiper-arribada-2024", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
})

const swiperJocs2024 = new Swiper(".swiper-jocs-2024", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
})

const swiperSopar2024 = new Swiper(".swiper-sopar-2024", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
})

