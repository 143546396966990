const toggle   = document.querySelector('.header__toggle')
const logo     = document.querySelector('.header__logo')
const nav      = document.querySelector('.header__nav')
const overflow = document.querySelector('.overflow')
const open     = document.querySelector('.menu-open')
const close    = document.querySelector('.menu-close')

toggle.addEventListener('click', () => {
    overflow.classList.toggle('isHidden')
    logo.classList.toggle('isVisible')
    nav.classList.toggle('isActive')
    open.classList.toggle('isVisible')
    close.classList.toggle('isVisible')
})